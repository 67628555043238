import { Link } from "react-router-dom";
import { logo } from "../utils/Images";
import { generalInfo } from "../utils/config";
import IframeResizer from "@iframe-resizer/react";

const Footer = () => {
  return (
    <>
      <div className="hc-footer hc-spacer-top hc-spacer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hc-footer-logo">
                <Link href="index.html"><img src={logo} alt="logo" /></Link>
              </div>
            </div>
            <div className="col-lg-12 mb-4">
              <ul className="hc-footer-contact-list">
                <li>
                  <div className="hc-foot-contact-box">
                    <h2>Office Address</h2>
                    <p dangerouslySetInnerHTML={{ __html: generalInfo.address }} />
                  </div>
                </li>
                <li>
                  <div className="hc-foot-contact-box">
                    <h2>Contact Us</h2>
                    <p dangerouslySetInnerHTML={{ __html: generalInfo.contact }} />
                  </div>
                </li>
                <li>
                  <div className="hc-foot-contact-box">
                    <h2>Email Us</h2>
                    <p dangerouslySetInnerHTML={{ __html: generalInfo.email }} />
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-12">
              <IframeResizer
                license="xxxx"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.4454600780405!2d76.76538667584089!3d19.26298504607458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd019df77e57bcf%3A0x245da700358f604!2sHayat%20Hospital!5e0!3m2!1sen!2sin!4v1723436225202!5m2!1sen!2sin"
                style={{ width: '100%', height: '300px' }}
                waitForLoad
              />
              {/* <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.4454600780405!2d76.76538667584089!3d19.26298504607458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd019df77e57bcf%3A0x245da700358f604!2sHayat%20Hospital!5e0!3m2!1sen!2sin!4v1723436225202!5m2!1sen!2sin" width="100%" height="300" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            </div>
            {/* <div className="col-lg-3 col-md-6">
              <div className="hc-footer-links">
                <ul>
                  <li>
                    <Link to="">Pediatric Clinic</Link>
                  </li>
                  <li>
                    <Link to="">Dental Clinic</Link>
                  </li>
                  <li>
                    <Link to="">General Surgery</Link>
                  </li>
                  <li>
                    <Link to="">Physiotherapy</Link>
                  </li>
                  <li>
                    <Link to="">Ltricies lputate</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="hc-footer-links">
                <ul>
                  <li>
                    <Link to="">Cardiologist</Link>
                  </li>
                  <li>
                    <Link to="">Surgery</Link>
                  </li>
                  <li>
                    <Link to="">Gynecologist</Link>
                  </li>
                  <li>
                    <Link to="">Pediatricians</Link>
                  </li>
                  <li>
                    <Link to="">Dentist</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="hc-footer-links">
                <ul>
                  <li>
                    <Link to="">Psychological</Link>
                  </li>
                  <li>
                    <Link to="">Cardiology</Link>
                  </li>
                  <li>
                    <Link to="">Gynecology</Link>
                  </li>
                  <li>
                    <Link to="">Breast Surgeon</Link>
                  </li>
                  <li>
                    <Link to="">Pediatrician</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="hc-footer-links">
                <ul>
                  <li>
                    <Link to="">CT Scan Center</Link>
                  </li>
                  <li>
                    <Link to="">Medical Store</Link>
                  </li>
                  <li>
                    <Link to="">Pediatric Clinic</Link>
                  </li>
                  <li>
                    <Link to="">Physiotherapy</Link>
                  </li>
                  <li>
                    <Link to="">Gynecologist</Link>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <!-- footer end -->
      <!-- copyright start --> */}
      <div className="hc-copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hc-copyright-box" style={{ display: "flex", justifyContent: "space-between" }}>
                <p>Copyright © { } {generalInfo.firm}. All Right Reserved.</p>
                <p>Powered By: <Link to="https://www.inshatech.com" className="text-warning" target="_blank">Insha Technologies</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- copyright end --> */}
    </>
  )
};

export default Footer

import React, { useState } from 'react'
import { Header } from '../components/Header'
import { TopHeader } from '../components/TopHeader'
import { Banner } from '../components/Banner'
import Service from '../components/Service'
import About from '../components/About'
import Team from '../components/Team'
import Contact from '../components/Contact'
import Counter from '../components/Counter'
import Blog from '../components/Blog'
import Footer from '../components/Footer'
import GoToTop from '../components/GoToTop'
import Preloader from '../components/Preloader'

export const Home = () => {

  const [isSticky, setIsSticky] = useState(false);
  const [showGoTop, setShowGoTop] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const header = document.querySelector('.hc-main-header');
    if (header) {
      const headerHeight = header.clientHeight;
      setIsSticky(scrollY >= headerHeight);
    }
    setShowGoTop(scrollY >= 300);
  };

  const handleGoTopClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Preloader />
      <TopHeader />
      <Header isSticky={isSticky} handleGoTopClick={handleGoTopClick} />
      <Banner />
      <Service />
      <About />
      <Team />
      <Counter />
      <Contact />
      <Blog />
      <Footer />
      <GoToTop handleGoTopClick={handleGoTopClick} handleScroll={handleScroll} showGoTop={showGoTop} />
    </>
  )
}

import { all, car, dia, DIABETES_CAMP, gm, neu, res, sp, team1, team2, team3, team4, team5, team6, team7, thy } from "../utils/Images";
const menu = [
  {
    title: "Home",
    url: "#home",
  },
  {
    title: "About",
    url: "#about",
  },
  {
    title: "Team",
    url: "#team",
  },
  {
    title: "Achievements",
    url: "#achievements",
  },
  {
    title: "Contact Us",
    url: "#contact",
  },
];

const generalInfo = {
  firm: 'Hayat Hospital',
  mobileSingle: 917722022876,
  contact: 'Phone: <a href="callTo:912452224800" class="text-white">02452 224800</a> </br>Mobile: <a href="callTo:917722022876" class="text-white">+91-7722022876</a> <a href="callTo:919028645505" class="text-white">+91-9028645505</a>',
  timing: '10.00 AM - 8.30 PM (Mon - Sat) - (Emergency: 24hrs)',
  email: '<a href="mailto:contact@hayathospital.co.in" class="text-white">contact@hayathospital.co.in</a>',
  address: 'GAWHANE ROAD, Parbhani, Maharashtra 431401 INDIA'
}

const doctors = [
  { name: "Dr Shoeb Ansari", expertise: "Daibetilogist", image: team1, description: "MBBS MD (MEDICINE) ACDMD Advance certification in diabetes & metabolic disorders (NHS ENGLAND) Physician daibetilogist thyroid & heart care consultant", mobile: "", facebook: "", linkedin: "" },
  { name: "Dr Samreen Shoeb Ansari", expertise: "Gynecologist", image: team2, description: "MBBS DGO (Obstetrician Gynecologist & Infertility Specialist)", mobile: "", facebook: "", linkedin: "" }
]

const visitingDoctors = [
  { name: "Dr Shanawz Ali", expertise: "Cardiologist", image: team3, description: "MBBS MD (MEDICINE) DNB Cardiology - Care Hospital Hyderabad Consultant, Interventional Cardiologist", mobile: "", facebook: "", linkedin: "" },
  { name: "Dr Asif Ahmed", expertise: "Neurologist", image: team7, description: "MBBS MD M CH Neuro Surgery AFMC (Pune) FRCS (UK) Brain and Spine Surgeon", mobile: "", facebook: "", linkedin: "" },
  { name: "Dr M.A Waheed", expertise: "Neurologist", image: team4, description: "MBBS DNB DM Neurology Consultants Neurologist", mobile: "", facebook: "", linkedin: "" },
  { name: "Dr Tauseef Ahmed", expertise: "Nephrologist", image: team5, description: "MBBS MD DM Nephrology Consultants Nephrologist & Transplant Physician", mobile: "", facebook: "", linkedin: "" },
  { name: "Dr Azhar Patel", expertise: "Urologist", image: team6, description: "MBBS MS (Surgery) Mch(Urology) CONSULTANT Urologist, Andrologist &  transplant Surgeon", mobile: "", facebook: "", linkedin: "" }
]

const services = [
  { title: "General Medicine", description: "This is demo text", image: gm },
  { title: "Diabetes", description: "This is demo text", image: dia },
  { title: "Thyroid", description: "This is demo text", image: thy },
  { title: "Neurology", description: "This is demo text", image: neu },
  { title: "Respiratory", description: "This is demo text", image: res },
  { title: "Cardiology", description: "This is demo text", image: car },
  { title: "Allergy", description: "This is demo text", image: all },
  { title: "Sexual Problems", description: "This is demo text", image: sp },
]

const features = [
  "ICU WITH ALL FACILITIES",
  "OPD",
  "GENERAL WARD",
  "SUGAR",
  "BP",
  "ECG",
  "NEBULISER",
  "PULSE OXIMETER",
  "MULTI PARA MONITOR",
  "CENTRAL OXYGEN"
];

const achievements = [
  { title: "DIABETES CAMP", description: "EVERY THURSDAY FREE BLOOD SUGAR FOR ALL SUGAR PATIENTS", image: DIABETES_CAMP, date: "12-08-2024", readeMore: false }
]

export { menu, generalInfo, doctors, visitingDoctors, services, features, achievements };

import { Link } from "react-router-dom";
import { doctors, visitingDoctors } from "../utils/config";
const Team = () => {
  return (
    <>
      {/* <!-- team start --> */}
      <div className="hc-team hc-spacer-top" id="team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hc-heading-wrap text-center">
                <h2 className="hc-sub-heading">Our Doctors</h2>
                <h1 className="hc-heading">We are happy to help you</h1>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="hc-team-grid" style={{ display: "flex", flexWrap: "wrap" }}>
                {
                  doctors.map(item =>
                    <div key={item.name} className="hc-team-box Cardiologist Pediatricians">
                      {/* <span className="hc-dr-rating"><i className="fas fa-star"></i> 4.5</span> */}
                      <div >
                        <img style={{borderRadius: "10%"}} src={item.image} alt="team" />
                      </div>
                      <h1 className="hc-team-name">{item.name}</h1>
                      <h2 className="hc-team-designation">{item.expertise}</h2>
                      <p style={{textTransform: "uppercase"}}>{item.description}</p>
                      <ul className="hc-team-social">
                        <li>
                          <Link to={item.facebook}><i className="fab fa-facebook-f"></i></Link>
                        </li>
                        <li>
                          <Link to={item.facebook}><i className="fab fa-twitter"></i></Link>
                        </li>
                        <li>
                          <Link to={`to:${item.mobile}`}><i className="fas fa-phone-alt"></i></Link>
                        </li>
                      </ul>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="col-lg-12">
              <div className="hc-heading-wrap text-center">
                <h2 className="hc-sub-heading">Visiting Doctors</h2>
                <h1 className="hc-heading">Super Specialty</h1>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="hc-team-grid" style={{ display: "flex", flexWrap: "wrap" }}>
                {
                  visitingDoctors.map(item =>
                    <div key={item.name} className="hc-team-box Cardiologist Pediatricians">
                      {/* <span className="hc-dr-rating"><i className="fas fa-star"></i> 4.5</span> */}
                      <div >
                        <img style={{borderRadius: "10%"}} src={item.image} alt="team" />
                      </div>
                      <h1 className="hc-team-name">{item.name}</h1>
                      <h2 className="hc-team-designation">{item.expertise}</h2>
                      <p style={{textTransform: "uppercase"}}>{item.description}</p>
                      <ul className="hc-team-social">
                        <li>
                          <Link to={item.facebook}><i className="fab fa-facebook-f"></i></Link>
                        </li>
                        <li>
                          <Link to={item.facebook}><i className="fab fa-twitter"></i></Link>
                        </li>
                        <li>
                          <Link to={`to:${item.mobile}`}><i className="fas fa-phone-alt"></i></Link>
                        </li>
                      </ul>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- team end --> */}
    </>
  )
};

export default Team

import { features } from "../utils/config";
import { counter1 } from "../utils/Images";

const Counter = () => {
  return (
    <>
      <div className="hc-counter hc-spacer-top hc-spacer-bottom" id="counter">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hc-heading-wrap text-center">
                <h2 className="hc-sub-heading">Our Features</h2>
                <h1 className="hc-heading">Why choose us</h1>
              </div>
            </div>
          </div>
          <div className="hc-counter-wrap">
            <div className="row">
              {features.map((feature, index) => (
                <div className="col-xl-3 col-md-6" key={index}>
                  <div className="hc-counter-box">
                    <div className="hc-count-data">
                      <div className="hc-count-icon">
                        <img src={counter1} alt="counter" />
                      </div>
                      <div className="hc-count-head">
                        <h2>{feature}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Counter

import { map } from "../utils/Images";
import { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

const Contact = () => {
  const form = useRef();
  const [isProcessing, setIsProcessing] = useState(false);

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const validateMobile = (mobile) => {
    const regex = /^[6-9]\d{9}$/;
    return regex.test(mobile);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const email = form.current.user_email.value;
    const mobile = form.current.user_mobile.value;

    if (!validateEmail(email)) {
      setIsProcessing(false);
      Swal.fire('Invalid Email!', 'Please enter a valid email address.', 'error');
      return;
    }

    if (!validateMobile(mobile)) {
      setIsProcessing(false);
      Swal.fire('Invalid Mobile!', 'Please enter a valid 10-digit Indian mobile number.', 'error');
      return;
    }

    emailjs
      .sendForm('service_wgsgszq', 'template_1qjcy4q', form.current, {
        publicKey: '_0P8bkgJjeSWLXg9D',
      })
      .then(
        () => {
          Swal.fire('Success!', 'Your message has been sent successfully.', 'success');
          form.current.reset();
          setIsProcessing(false);
        },
        (error) => {
          Swal.fire('Failed!', 'Something went wrong. Please try again later.', 'error');
          setIsProcessing(false);
        },
      );
  };
  return (
    <>
      <div className="hc-contact hc-spacer-high hc-spacer-bottom" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 offset-lg-1">
              <div className="hc-heading-wrap">
                <h2 className="hc-sub-heading">Contact Us</h2>
                <h1 className="hc-heading">Get in touch</h1>
              </div>
              <div className="hc-map-box">
                <img src={map} alt="map" />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="hc-contact-box">
                <form ref={form} onSubmit={sendEmail}>
                  <label>Name</label>
                  <input type="text" name="user_name" id="user_name" className="require" required />
                  <label>Email</label>
                  <input type="email" name="user_email" id="user_email" className="require" data-valid="email" data-error="Email should be valid." required />
                  <label>Mobile No.</label>
                  <input type="number" name="user_mobile" id="user_mobile" className="require" required />
                  <label>Message</label>
                  <textarea name="user_message" id="message" className="require"></textarea>
                  {/* <button type="submit" className="hc-btn submitForm">Submit</button> */}
                  <button type="submit" className="hc-btn submitForm" disabled={isProcessing}>
                    {isProcessing ? 'Processing...' : 'Submit'}
                  </button>
                  <div className="response"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Contact

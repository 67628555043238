import { Modal, Button, Form } from 'react-bootstrap';

const AppointmentModal = ({ show, handleClose }) => {

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">Appointment Form</h5>
          <button type="button" className="close" onClick={handleClose} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <div className="hc-contact-box">
            <Form>
              <Form.Group controlId="full_name">
                <Form.Control type="text" placeholder="Name" name="full_name" required />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Control type="email" placeholder="Email" name="email" required />
              </Form.Group>
              <Form.Group controlId="contact">
                <Form.Control type="number" placeholder="Contact No." name="contact" required />
              </Form.Group>
              <Form.Group controlId="message">
                <Form.Control as="textarea" placeholder="Message" name="message" required />
              </Form.Group>
              <Button variant="primary" type="submit" className="hc-btn submitForm">
                Submit
              </Button>
              <div className="response"></div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { AppointmentModal };

import React, { useState } from 'react'
import { logo } from '../utils/Images'
import { AppointmentModal } from './AppointmentModal'
import { Link } from 'react-router-dom';
import { menu } from '../utils/config';

export const Header = ({ isSticky, handleGoTopClick }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showMenu, setShowMenu] = useState(!true);

  const handleClick = (item) => {
    if (item.title === 'Home') {
      handleGoTopClick();
    }
    setShowMenu();
  };

  return (
    <>
      {/* <!-- main header start --> */}
      <div className={`hc-main-header ${isSticky ? 'hc-header-sticky' : ''} ${showMenu ? 'hc-open-menu' : ''} `}>
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="hc-logo">
                <Link to="/" onClick={handleGoTopClick} >
                  <img src={logo} alt="logo" />
                </Link>
              </div>
            </div>
            <div className="col-md-9 col-sm-6">
              <div className="hc-main-head-flex">
                <div className="hc-main-menu ">
                  <div className="hc-navbar">
                    <ul>
                      {
                        menu.map((item) =>
                          <li key={item.title}>
                            <a href={item.url} onClick={handleClick}>{item.title}</a>
                          </li>
                        )
                      }
                    </ul>
                  </div>
                  <div className="hc-menu-toggle" onClick={setShowMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className="hc-head-btn">
                  <button className="hc-btn" onClick={handleShow}>Appointment</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- main header end --> */}

      <AppointmentModal show={show} handleClose={handleClose} />
    </>
  )
}

import { aboutImg } from "../utils/Images";

const About = () => {
  return (
    <>
      {/* <!-- about start --> */}
      <div className="hc-about hc-spacer-top hc-spacer-bottom" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 offset-lg-1">
              <div className="hc-about-img">
                <div className="hc-image-style">
                  <img src={aboutImg} alt="about" />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="hc-about-content">
                <div className="hc-heading-wrap">
                  <h2 className="hc-sub-heading">About Us</h2>
                  <h1 className="hc-heading">Welcome to our official website</h1>
                </div>
                <div className="tab-content hc-tab-content">
                  <p>Hayat Hospital accompanies well EQUIPPED ICU general ward Central oxygen multi para monitor defibrillator pulse oximeter nebuliser
                    Dr Shoeb Ansari owner of Hyatt Hospital is a meritorious candidate persued MBBS and M D medicine from government medical College Aurangabad  and actively practicing in patients service for the last 7 years.</p>
                  <p>Hospital is situated in the heart of the city GAWHANE Road near New Honda Parbhani which is convenient and easily accessible for the peoples of city as well as for the patients of peripheri. Hayat Hospital offers excellent medical care at an affordable prices And team of hayat Hospital is committed to provide best possible care to our patients.</p>
                  <p>If you need medical assistance please do not hesitate to contact us we will be happy to help you
                    Hayat Hospital is trusted and reliable Hospital in Parbhani providing 24 hours emergency services at affordable prices</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about end --> */}
    </>
  )
};

export default About

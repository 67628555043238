import React from 'react'
import { bannerImage } from '../utils/Images'

export const Banner = () => {
  return (
    <>
      {/* <!-- banner start --> */}
      <div className="hc-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="hc-banner-content">
                <h2>Our Introduction</h2>
                <h1>HAYAT HOSPITAL <br /> ICU, MATERNITY hOME & DIABETES SUPER SPECIALTY CENTER</h1>
                <div className="hc-banner-box">
                  <p>We <b>HAYAT HOSPITAL</b> providing wide range of services in Parbhani for the last 7 years
                    HAYAT HOSPITAL offers variety of services including GENERAL MEDICINE, DIABETES, THYROID, CARDIAC PROBLEMS, RESPIRATORY ILLNESSES, NEUROLOGICAL DISORDERS  AND SEXUAL PROBLEMS</p>
                </div>
                <a href="#about" className="hc-btn">Read More</a>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="hc-banner-img">
                <img src={bannerImage} alt="" />
              </div>
            </div>
          </div>
          <div className="hc-banner-shape">
            <span></span>
          </div>
        </div>
      </div>
      {/* <!-- banner end --> */}
    </>
  )
}

import { Link } from "react-router-dom";
import { achievements } from "../utils/config";

const Blog = () => {
  return (
    <>
      <div class="hc-blog hc-spacer-top" id="achievements">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="hc-heading-wrap text-center">
                <h2 class="hc-sub-heading">Our Achievements</h2>
                <h1 class="hc-heading">Latest Update</h1>
              </div>
            </div>
            {
              achievements.map(achievement =>
                <div class="col-lg-4 col-md-6">
                  <div class="hc-blog-box">
                    <div class="hc-blog-img">
                      <Link to=""><img src={achievement.image} alt="blog" /></Link>
                    </div>
                    <div class="hc-blog-data">
                      <div class="hc-blog-head">
                        <Link to="" class="hc-blog-date">{achievement.date}</Link>
                        {/* <ul class="hc-blog-social">
                      <li>
                        <Link to=""><i class="fab fa-facebook-f"></i></Link>
                      </li>
                      <li>
                        <Link to=""><i class="fab fa-twitter"></i></Link>
                      </li>
                      <li>
                        <Link to=""><i class="fab fa-instagram"></i></Link>
                      </li>
                    </ul> */}
                      </div>
                      <Link to="">
                        <h1 class="hc-blog-title">{achievement.title}</h1>
                      </Link>
                      <p>{achievement.description}</p>
                      {achievement.readeMore && <Link to="" class="hc-blog-more">Read More <i class="fas fa-long-arrow-alt-right"></i></Link>}
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
};

export default Blog

import React, { useEffect, useState } from 'react';
import { loading } from '../utils/Images';

const Preloader = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // match your jQuery delay

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading && (
        <div id="hc-preloader" className='bg-white'>
          <div className="hc-loading">
            <img src={loading} alt="" />
          </div>
        </div>
      )}
    </>
  );
};

export default Preloader;

import { useEffect } from "react";

const GoToTop = ({handleGoTopClick, handleScroll, showGoTop}) => {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <div
        className={`hc-go-top ${showGoTop ? 'hc-go-top-show' : ''}`}
        onClick={handleGoTopClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 384" style={{ transform: 'rotate(-45deg)' }}>
          <path d="M373.333,0H138.667C132.771,0,128,4.771,128,10.667V32c0,5.896,4.771,10.667,10.667,10.667h172.5L3.124,350.708
                c-2.042,2.043-3.082,4.707-3.122,7.383c-0.042,2.783,1,5.579,3.122,7.703l15.082,15.081c2.009,2.01,4.621,3.051,7.255,3.121
                c2.824,0.076,5.674-0.965,7.829-3.121L341.333,72.833v172.5c0,5.896,4.771,10.667,10.667,10.667h21.333
                c5.896,0,10.667-4.771,10.667-10.667V10.667C384,4.771,379.229,0,373.333,0z"></path>
        </svg>
      </div>
    </>
  )
};

export default GoToTop

import { Link } from "react-router-dom";
import { services } from "../utils/config";

const Service = () => {
  return (
    <>
      {/* <!-- services start --> */}
      <div className="hc-service hc-spacer-top" id="service">
        <div className="container">
          <div className="row">
            {
              services.map((service) => (
                <div className="col-lg-3 col-md-6" key={service.id}>
                  <div className="hc-service-box">
                    <div className="hc-service-ico-clr1 mb-4">
                      <img src={service.image} alt="service" />
                    </div>
                    <h1 className="hc-service-title">{service.title}</h1>
                    <p>{service.description}</p>
                    <Link to="#" className="hc-service-arrow">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 493.356 493.356">
                        <path d="M490.498,239.278l-109.632-99.929c-3.046-2.474-6.376-2.95-9.993-1.427c-3.613,1.525-5.427,4.283-5.427,8.282v63.954H9.136
              c-2.666,0-4.856,0.855-6.567,2.568C0.859,214.438,0,216.628,0,219.292v54.816c0,2.663,0.855,4.853,2.568,6.563
              c1.715,1.712,3.905,2.567,6.567,2.567h356.313v63.953c0,3.812,1.817,6.57,5.428,8.278c3.62,1.529,6.95,0.951,9.996-1.708
              l109.632-101.077c1.903-1.902,2.852-4.182,2.852-6.849C493.356,243.367,492.401,241.181,490.498,239.278z"/>
                      </svg>
                    </Link>
                  </div>
                </div>
              ))
            }

          </div>
        </div>
      </div>
      {/* <!-- services end --> */}
    </>
  )
};

export default Service
